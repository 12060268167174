@import "_variables";
html{
  overflow-x: hidden;
  font-size: 100%;
  @media(max-width: 767px){
    font-size: 95%;
  }
}
body{
  overflow-x: hidden;
}

nav.navbar.bootsnav{
 ul.nav{
   li{
     a{
       font-weight: bold;
       color: #000000;
       text-transform: uppercase;
     }
     &.dropdown{
       a.dropdown-toggle:after{
         display: none;
       }
     }
   }
 }



  li.dropdown{
    ul.dropdown-menu{
      -webkit-box-shadow: 0px 15px 22px -13px rgba(0,0,0,0.6);
      -moz-box-shadow: 0px 15px 22px -13px rgba(0,0,0,0.6);
      box-shadow: 0px 15px 22px -13px rgba(0,0,0,0.6);
      border-top:none!important;
      li{
        a{
          text-transform: uppercase!important;
          font-weight: bold;
          color: #000000;
        }
      }
    }
  }
}

.title-image-back{
height: auto;
padding-top:110px;
@media(max-width: 991px){
  padding-top: 60px;
}
padding-bottom: 0px;
.container{
  padding-top: 40px;
  padding-bottom: 40px;
}
&.dark{
    .tag{
      h1{
      }
    }
  }
.tag{
  position: relative;
  max-width: 100%;
  left: 0;
  bottom: auto;
  h1{
    text-shadow: none;
    font-size: 3.5rem;
  }
}
}
.full-screen-billboard{
  h2,h3{
    margin-left: 0!important;
  }
}
p{
  font-size: 1.4rem;
}
h2{
  font-size: 2.4rem;
}
h3{
  font-size: 2rem;
}
@media(min-width:768px){
  .navbar-brand{
    height:110px!important;
  }
  .navbar-brand img{
    max-height:80px!important;
  }
}
nav.navbar.bootsnav ul.nav > li > a{
  color:#000000!important;
}
.image-title-description-vertical p{
  font-size:1.2rem!important;
  font-weight:normal!important;
}

.no-margin-grid-block {

  .block {

      .content {
        width: 97%;
        background-color: rgba(0,0,0,.5);
        padding: 15px;
        h3{
            background-color: transparent;
            padding: 0;
            line-height: 2.0rem;
            font-size: 1.9rem;
            text-transform: uppercase;
            span{
              background-color: transparent;
              background: transparent;
                -webkit-box-decoration-break: clone;
                box-decoration-break: clone;
            }
        }
        p{
            padding: 0px;
            margin-top: 0px;
            font-size: 1.4rem;
            line-height: 1.1em;
            background-color: transparent;
        }
      }

  }
}

.second-button-row .action-buttons{
  padding-top:0px!important;
}
.mobile-block-buttons{
     .content-container{
      .action-buttons{
        .btn{
          @media(max-width:767px){
            width:100%!important;
          }
      }
    }
  }
}
.leadership{
  margin-bottom: 25px;
  text-align: center;
  h3{
    text-transform: uppercase;
    font-size: 1.8rem;
    letter-spacing: -.2px;
    margin-bottom: 5px;
    padding-bottom: 3px;
    border-bottom:1px #000 solid;
    font-weight: bold;
  }
  h4{
    margin-top: 5px;
  }
}

.base-footer{
  background-color: #000000;
  border-top:2px #000 solid;
}

.one-church-lander{
  p{
    color: #FFFFFF!important;
  }
  .map-back{
    @media(max-width: 1199px){
      padding: 80px 0!important;
    }
    @media(max-width: 991px){
      padding: 120px 0!important;
    }
    @media(max-width: 767px){
      height: 100vh!important;
      padding: 20px 0!important;
      .one-church-link,.one-center-link{
        width: 60%!important;
      }
    }
  }
}
